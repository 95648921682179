#CybotCookiebotDialog {
  font-family: "SofiaPro", sans-serif !important;
  //     background: var(--primary);

  //     & > div {
  //       display: none;
  //     }

  button.CybotCookiebotDialogBodyButton {
    font-family: "GTAmericaMono", sans-serif !important;
    font-weight: 400;
    border-radius: 100px;
  }

  //     #CybotCookiebotDialogFooter {
  //       #CybotCookiebotDialogBodyButtonAccept,
  //       #CybotCookiebotDialogBodyLevelButtonAccept,
  //       #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  //         background-color: #ffffff;
  //         border-color: #000000;
  //         color: #000000;
  //         border-radius: 100px;
  //       }
  //     }

  //     //     #CybotCookiebotDialogBodyButtonDecline,
  //     //     #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  //     //       border-color: var(--primary);
  //     //     }
  //     //   }

  //     //   #CybotCookiebotDialogTabContent
  //     //     input:checked
  //     //     + .CybotCookiebotDialogBodyLevelButtonSlider {
  //     //     background-color: var(--primary);
  //     //   }

  //     #CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
  //       background-color: var(--background);
  //     }
}
