/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #f3ff9b;
  --secondary: #b4b426;
  --tertiary: #b4b426;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/CTA_skugga_moerkgul_2_1_c804fd9832.png);
  --shadow-ball: url(/images/content/urinkollen_boll_600px_550306517f.png);
  --shadow-line: url(/images/content/urinkollen_kant_600px_d5558b6917.png);
}