@font-face {
  font-family: "GTAmericaMono";
  src: url("/fonts/GTAmericaMono/GTAmericaMono-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SofiaPro";
  src: url("/fonts/SofiaPro/SofiaPro-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SofiaPro";
  src: url("/fonts/SofiaPro/SofiaPro-SemiBold.ttf");
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}
